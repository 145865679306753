import React from 'react';
import { Menu, MenuLabel, MenuContent, MenuItem, InlineTextButton } from '..';
import classNames from 'classnames';
// import { useConfiguration } from '../../context/configurationContext';
import englishImage from './flags/english.png';
import arabImage from './flags/arab.png';

import css from './LocaleMenu.module.css';
import { getDefaultLocale } from '../../util/genericHelpers';

const localeOptions = [
  { key: 'ar', label: 'Arab', flag: arabImage },
  { key: 'en-US', label: 'English', flag: englishImage },
];

const LocaleMenu = props => {
  const currentLocaleFromStorage = getDefaultLocale();

  const onChangeLocale = (e, locale) => {
    e.preventDefault();
    e.stopPropagation();

    if (typeof window !== 'undefined') {
      if (currentLocaleFromStorage !== locale) {
        window.localStorage.setItem('locale', locale);
        window.location.reload();
      }
    }
  };

  const locale = localeOptions.find(l => l?.key === currentLocaleFromStorage);

  return (
    <Menu className={css.LocaleMenuMain}>
      <MenuLabel className={css.menuLabel}>
        <span className={css.countryImg}>
          <img className={css.localeFlag} src={locale?.flag} alt={locale?.label} />
        </span>

        <svg
          width="17"
          height="10"
          viewBox="0 0 17 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.5 1L8.32927 8L15.5 1" stroke="white" strokeWidth="2" />
        </svg>
      </MenuLabel>
      <MenuContent className={css.menuContent} arrowPosition={30} style={{ right: '100px' }}>
        {localeOptions.map(l => {
          const menuItemClasses = classNames(css.menuItem, {
            [css.menuItemActive]: locale?.key === l.key,
          });
          return (
            <MenuItem key={l.key} className={menuItemClasses}>
              <InlineTextButton
                className={css.menuItemButton}
                type="button"
                onClick={event => onChangeLocale(event, l?.key)}
              >
                <img className={css.flag} src={l?.flag} alt={l?.countryName} />
                <span className={css.label}>{l?.label}</span>
              </InlineTextButton>
            </MenuItem>
          );
        })}
      </MenuContent>
    </Menu>
  );
};

export default LocaleMenu;
